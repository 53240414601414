import React from "react";
import { Link } from "react-router-dom";
import Box from "../Flex/Box";
import FlexV from "../Flex/FlexV";
import FlexH from "../Flex/FlexH";
import SectionH from "../Flex/SectionH";
import SectionV from "../Flex/SectionV";
import Spacer from "../Flex/Spacer";
import Divider from "../Flex/Divider";
import colors from "../../constants/colors";
import { useMediaQuery } from "react-responsive";

const Footer = ({ ...props }) => {
  const isSmallScreen = useMediaQuery({ query: "(max-width: 600px)" });
  const isTinyScreen = useMediaQuery({ query: "(max-width: 400px)" });

  return (
    <SectionV {...props} style={styles.footer}>
      <Box>
        <FlexH
          style={{
            ...styles.sections,
            flexDirection: isSmallScreen ? "column" : "row",
          }}
          alignment={isSmallScreen ? "center" : "flex-start"}
          gap={30}
        >
          <div style={styles.logo}>
            <img
              style={styles.logoImg}
              src="/icons/elevate3d_white.svg"
              alt="logo"
            />
            Elevate3D
          </div>

          {!isSmallScreen && <Spacer />}

          <FlexH
            gap={25}
            alignment="flex-start"
            flexWrap="nowrap"
            style={{ flexDirection: isTinyScreen ? "column" : "row" }}
          >
            <FlexV style={styles.section} alignment="flex-start" gap={15}>
              <div style={styles.sectionTitle}>Legal</div>
              <Link style={styles.link} to="/terms">
                Terms
              </Link>
              <Link style={styles.link} to="/privacy">
                Privacy Policy
              </Link>
            </FlexV>

            <FlexV style={styles.section} alignment="flex-start" gap={15}>
              <div style={styles.sectionTitle}>Contact us</div>
              <a style={styles.link} href="https://mycoloranalysis.ai">
                My Color Analysis AI
              </a>
              <a style={styles.link} href="https://coloringpagesjoy.com">
                Coloring Pages Joy
              </a>
              <a style={styles.link} href="https://howinfrench.com">
                How In French
              </a>
              <a style={styles.link} href="https://eyecolor.ai">
                Eye Color AI
              </a>
              <a style={styles.link} href="https://www.alexcarlier.com">
                Alex Carlier
              </a>
              <a style={styles.link} href="https://www.thumbnailspro.com">
                ThumbnailsPro
              </a>
              <a style={styles.link} href="mailto:hello@elevate3d.ai">
                hello@elevate3d.ai
              </a>
            </FlexV>
          </FlexH>
        </FlexH>
      </Box>
      <Divider darkMode />
      <SectionH>
        <Box style={styles.copyrights}>
          <FlexH style={{ flexDirection: isSmallScreen ? "column" : "row" }}>
            <p style={styles.copyrightsCompany}>
              © 2024 Elevate3D. All rights reserved.
            </p>
            <p style={styles.copyrightsMade}>Made with ♥ in Paris / Zurich</p>
            <Spacer />
          </FlexH>
        </Box>
      </SectionH>
    </SectionV>
  );
};

export default Footer;

const styles = {
  footer: {
    backgroundColor: colors.black,
    zIndex: 10,
  },
  logo: {
    color: colors.white,
    fontSize: 24,
    display: "flex",
    alignItems: "center",
  },
  logoImg: {
    marginRight: 10,
    height: 35,
  },
  sections: {
    padding: 40,
  },
  section: {},
  links: {
    flex: 1,
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  },
  sectionTitle: {
    fontWeight: "bold",
    color: colors.white,
  },
  link: {
    color: colors.foregroundRegentGrey,
  },
  linkIcon: {
    display: "inline",
    marginRight: 10,
    opacity: 0.8,
  },
  copyrights: {
    padding: 20,
    lineHeight: "20px",
    color: colors.foregroundRegentGrey,
  },
  copyrightsCompany: {
    flex: 1,
    textAlign: "center",
  },
  copyrightsMade: {
    flex: 1,
    textAlign: "center",
  },
};
